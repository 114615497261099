export const KYCStatus = {
  InReview: 'In Review',
  Insufficient: 'Insufficient',
};

export const getKYCStatuses = () => {
  return Object.keys(KYCStatus).map(key => {
    return {
      value: key,
      label: KYCStatus[key],
    };
  });
};
