
<template>
  <div class="add-membership-policy-form">
    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <CForm @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME')" v-slot="{ errors }" rules="required|max:100">
            <CInput :label="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME')" v-model="policy.name" />
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </ValidationProvider>

          <div role="group" class="form-group" v-if="isEdit && policy.currencySymbol">
            <label class>{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY') }}</label>
            <div class="form-control-plaintext">
              <span>{{ policy.currencySymbol }}</span>
            </div>
          </div>

          <ValidationProvider name="ADD_AFFILIATE_POLICY_COMPONENT_CURRENCY_FIELD_NAME" v-slot="{ errors }" rules v-if="!isEdit">
            <div role="group" class="form-group">
              <label class>{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY') }}</label>
              <select v-model="policy.currencySymbol" class="form-control">
                <option value>{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_PLEASE_SELECT_LABEL') }}</option>
                <option v-for="item in currencyList" :key="item.value" :value="item.value">{{ item.label }}</option>
              </select>
            </div>
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </p>
          </ValidationProvider>

          <div role="group" class="form-group" v-if="isEdit">
            <label class>
              {{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE') }}
            </label>
            <div class="form-control-plaintext">
              <span>{{ $t('POLICY_TYPE_MEMBERSHIP_AFFILIATE') }}</span>
            </div>
          </div>

          <ValidationProvider :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE')" v-if="!isEdit" v-slot="{ errors }" rules="required">
            <CSelect :label="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE')" :value.sync="policy.type" :options="options" />
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </ValidationProvider>

          <ValidationProvider
            :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE')"
            v-slot="{ errors }"
            rules="required|min_value:0|max_value:100"
          >
            <div class="form-group">
              <label>
                {{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE') }}
              </label>
              <currency-input v-model="policy.proportionShare" :precision="2" :currency="{ suffix: '%' }" class="form-control" />
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </ValidationProvider>

          <div class="max-levels">
            <ValidationProvider
              :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_MAX_LEVEL')"
              v-slot="{ errors }"
              :rules="`required|min_value:1|max_value:${maxLevels}`"
            >
              <CInput
                :label="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_MAX_LEVEL')"
                v-model="policy.maxLevels"
                @change="onChangeMaxLevels"
              />
              <span class="error-msg-wrap">
                <span class="error-msg">{{ errors[0] }}</span>
              </span>
            </ValidationProvider>
          </div>

          <h2 class="label">{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_RATE_TITLE') }}:</h2>
          <div class="wrap-affiliate-form">
            <ul>
              <li v-for="(rate, index) in policy.rates" :key="index">
                <ValidationProvider
                  :name="`${$t('AFFILIATE_POLICIES_TEXT_LEVEL')} ${index + 1}`"
                  v-slot="{ errors }"
                  rules="required|min_value:0|max_value:100"
                >
                  <label>{{ $t('AFFILIATE_POLICIES_TEXT_LEVEL') }} {{ index + 1 }}</label>
                  <div class="group-rates">
                    <currency-input
                      v-model.number="policy.rates[index]"
                      :precision="2"
                      :currency="{ suffix: '%' }"
                      class="form-control rate-control"
                    />
                  </div>
                  <p class="group-err-msg">
                    <span class="error-msg mt-1">{{ errors[0] }}</span>
                  </p>
                </ValidationProvider>
              </li>
            </ul>
          </div>

          <h2 class="label">{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_MEMBERSHIP_TITLE') }}:</h2>
          <CDataTable class="membership-types-table" :items="paidMembershipTypes" :fields="fields" fixed hover striped bordered>
            <template ##="{item, index}">
              <td>{{ index + 1 }}</td>
            </template>

            <template #rate="{item}">
              <td class>
                <ValidationProvider
                  :name="`${$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_TABLE_COL_RATE_NAME')} ${item.name}`"
                  rules="required|min_value:0|max_value:100"
                >
                  <div slot-scope="{ errors }">
                    <div class="form-group">
                      <currency-input class="form-control" v-model="item.rate" :precision="2" :currency="{ suffix: '%' }" />
                    </div>
                    <span class="error-msg-wrap">
                      <span class="error-msg">{{ errors[0] }}</span>
                    </span>
                  </div>
                </ValidationProvider>
              </td>
            </template>
          </CDataTable>

          <CRow class="form-actions mt-3">
            <CCol lg="12" md="12">
              <CButton color="primary" class="btn btn-primary" @click="onSubmit">
                {{ $t('AFFILIATE_POLICIES_BUTTON_SAVE') }}
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { MembershipTypeName } from '@/enums';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { env } from '@/constants';

export default {
  name: 'AddMembershipAffiliatePolicy',
  props: {
    policy: {
      type: Object,
      default: () => {
        return {};
      },
    },
    options: {
      type: Array,
      default: () => [],
    },
    currencyList: {
      type: Array,
      default: () => [],
    },
    policyId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isEdit: false,
      fields: [
        {
          key: '#',
          label: this.$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_LABEL_NUMBER'),
        },
        {
          label: this.$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_TABLE_COL_NAME'),
          key: 'name',
        },
        {
          label: this.$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_TABLE_COL_RATE'),
          key: 'rate',
          _style: 'width: 250px',
        },
      ],
      paidMembershipTypes: [],
      maxLevels: env.policy.maxLevelsOfMembershipPolicy,
    };
  },
  computed: {
    ...mapGetters(['membershipTypes']),
  },
  mounted() {
    const paidMembershipTypes = this.membershipTypes
      .filter(e => e.is_enabled)
      .map(item => {
        return {
          ...item,
          rate: 0,
          displayOrder: item.display_order || 0,
        };
      });

    this.paidMembershipTypes = _.sortBy(paidMembershipTypes, 'displayOrder');
    this.onChangeMaxLevels();

    if (this.policyId) {
      this.isEdit = true;

      const paidMembershipTypes = this.paidMembershipTypes;
      Object.entries(this.policy.membership_rate).forEach(arrays => {
        const membershipTypeId = arrays[0];
        const membershipType = paidMembershipTypes.find(item => item.id === membershipTypeId);
        if (membershipType) {
          membershipType.rate = arrays[1];
        }
      });

      this.paidMembershipTypes = paidMembershipTypes;
    }
  },
  methods: {
    onChangeMaxLevels() {
      const maxLevels = this.policy.maxLevels ? Number(this.policy.maxLevels) : null;

      if (maxLevels > 0 && maxLevels <= this.maxLevels) {
        if (this.policy.rates.length > maxLevels) {
          this.policy.rates = _.slice(this.policy.rates, 0, maxLevels);
        } else if (this.policy.rates.length < maxLevels) {
          do {
            this.policy.rates.push(0);
          } while (this.policy.rates.length < maxLevels);
        }
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const membershipRates = this.paidMembershipTypes.map(e => {
        return {
          membership_type_id: e.id,
          rate: e.rate,
        };
      });
      const data = {
        name: this.policy.name,
        type: this.policy.type,
        currency_symbol: this.policy.currencySymbol || null,
        proportion_share: this.policy.proportionShare,
        description: this.policy.description,
        max_levels: this.policy.maxLevels,
        rates: this.policy.rates,
        membership_rates: membershipRates,
      };

      let msg = '';

      try {
        if (!this.isEdit) {
          msg = 'New policy has been created';
          await this.$http.post(endpoints.createAffiliatePolicy, data);
        } else {
          delete data.currency_symbol;
          msg = 'Policy has been updated';
          await this.$http.put(endpoints.updateAffiliatePolicy(this.policyId), data);
        }
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_SUCCESS_TITLE'),
          text: msg,
        });
        this.$router.back('affiliate-configuration/affiliate-policies');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
@mixin btn-handle-rate {
  width: 35px;
  height: 35px;
  font-size: 18px;
  line-height: 35px;
  font-weight: 700;
  color: #fff;
  background: #0d0da9;
  padding: 0;
  margin: 0 0 0 10px;
  border: none;
  box-shadow: none;
  vertical-align: top;
}
.add-membership-policy-form {
  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #657187;
    margin-bottom: 20px;
  }
  .wrap-affiliate-form {
    ul {
      padding: 0;
      list-style-type: none;
      li {
        span {
          display: flex;
          flex: 0 0 100%;
          width: 100%;
          flex-wrap: wrap;
          label {
            font-size: 14px;
            font-weight: 300;
            line-height: 32px;
            text-align: right;
            color: #3a4a7f;
            padding-right: 10px;
            width: 35%;
          }
          .group-rates {
            width: 65%;
            .rate-control {
              font-size: 16px;
              font-weight: 500;
              color: #3a4a7f;
              width: 100%;
            }
            button {
              @include btn-handle-rate;
            }
          }
          .group-err-msg {
            width: 65%;
            margin-left: 35%;
          }
        }
      }
    }
  }

  .membership-types-table {
    .form-group {
      .form-control {
        display: block;
        width: 100%;
      }
    }

    .error-msg-wrap {
      display: block;
      width: 100%;
    }
  }
}
</style>
