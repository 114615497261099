var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-membership-policy-form"},[_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME'),"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME')},model:{value:(_vm.policy.name),callback:function ($$v) {_vm.$set(_vm.policy, "name", $$v)},expression:"policy.name"}}),_c('p',{staticClass:"group-err-msg"},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),(_vm.isEdit && _vm.policy.currencySymbol)?_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v(" "+_vm._s(_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY'))+" ")]),_c('div',{staticClass:"form-control-plaintext"},[_c('span',[_vm._v(_vm._s(_vm.policy.currencySymbol))])])]):_vm._e(),(!_vm.isEdit)?_c('ValidationProvider',{attrs:{"name":"ADD_AFFILIATE_POLICY_COMPONENT_CURRENCY_FIELD_NAME","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v(_vm._s(_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY')))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.policy.currencySymbol),expression:"policy.currencySymbol"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.policy, "currencySymbol", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_PLEASE_SELECT_LABEL')))]),_vm._l((_vm.currencyList),function(item){return _c('option',{key:item.value,domProps:{"value":item.value}},[_vm._v(_vm._s(item.label))])})],2)]),_c('p',{staticClass:"group-err-msg"},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}):_vm._e(),(_vm.isEdit)?_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v(" "+_vm._s(_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE'))+" ")]),_c('div',{staticClass:"form-control-plaintext"},[_c('span',[_vm._v(_vm._s(_vm.$t('POLICY_TYPE_MEMBERSHIP')))])])]):_vm._e(),(!_vm.isEdit)?_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CSelect',{attrs:{"label":_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE'),"value":_vm.policy.type,"options":_vm.options},on:{"update:value":function($event){return _vm.$set(_vm.policy, "type", $event)}}}),_c('p',{staticClass:"group-err-msg"},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE'),"rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE'))+" ")]),_c('currency-input',{staticClass:"form-control",attrs:{"precision":2,"currency":{ suffix: '%' }},model:{value:(_vm.policy.proportionShare),callback:function ($$v) {_vm.$set(_vm.policy, "proportionShare", $$v)},expression:"policy.proportionShare"}})],1),_c('p',{staticClass:"group-err-msg"},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}),_c('CDataTable',{staticClass:"mt-4",attrs:{"items":_vm.paidMembershipTypes,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))])]}},{key:"rate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"field-membership-rate"},[_c('ValidationProvider',{attrs:{"name":((_vm.$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_TABLE_COL_RATE_NAME')) + " " + (item.name)),"rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-input',{staticClass:"form-control",attrs:{"precision":2,"currency":{ suffix: '%' }},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", $$v)},expression:"item.rate"}}),_c('p',{staticClass:"group-err-msg"},[_c('span',{staticClass:"error-msg mt-1"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]}}],null,true)}),_c('CRow',{staticClass:"form-actions mt-3"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[_c('CButton',{staticClass:"btn btn-primary",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('AFFILIATE_POLICIES_BUTTON_SAVE'))+" ")])],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }