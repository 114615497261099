export const ClaimRequestStatus = {
  Pending: 'Payout pending',
  Approved: 'Payout transferred',
  // Rejected: 'Payout rejected',
};

export const getClaimRequestStatuses = () => {
  return Object.keys(ClaimRequestStatus).map(key => {
    return {
      value: key,
      label: ClaimRequestStatus[key],
    };
  });
};
