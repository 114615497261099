
export const PaymentType = {
  Bank: 'Bank',
  BTC: 'BTC',
  ETH: 'ETH',
  USDT: 'USDT',
  ExternalBTC: 'External_BTC',
  ExternalETH: 'External_ETH',
  ExternalUSDT: 'External_USDT',
};

export const getPaymentTypes = () => {
  return Object.keys(PaymentType).map(key => {
    return {
      value: PaymentType[key],
      label: PaymentType[key],
    };
  });
};
