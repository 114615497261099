
export const CryptoPlatformType = {
  BTC: 'BTC',
  BTCSW : 'BTCSW',
  ETH: 'ETH',
  USDT: 'USDT',
};

export const getCryptoPlatformTypes = () => {
  return Object.keys(CryptoPlatformType).map(key => {
    return {
      value: key,
      label: CryptoPlatformType[key],
    };
  });
};
