<template>
  <div class="add-membership-policy-form">
    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <CForm @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
            :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME')"
            v-slot="{ errors }"
            rules="required|max:250"
          >
            <CInput :label="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME')" v-model="policy.name" />
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{errors[0]}}</span>
            </p>
          </ValidationProvider>

          <div role="group" class="form-group" v-if="isEdit && policy.currencySymbol">
            <label class>{{$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY')}}</label>
            <div class="form-control-plaintext">
              <span>{{policy.currencySymbol}}</span>
            </div>
          </div>

          <ValidationProvider name="ADD_AFFILIATE_POLICY_COMPONENT_CURRENCY_FIELD_NAME" v-slot="{ errors }" rules v-if="!isEdit">
            <div role="group" class="form-group">
              <label class>{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY') }}</label>
              <select v-model="policy.currencySymbol" class="form-control">
                <option value>{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_PLEASE_SELECT_LABEL') }}</option>
                <option v-for="item in currencyList" :key="item.value" :value="item.value">{{ item.label }}</option>
              </select>
            </div>
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </p>
          </ValidationProvider>

          <div role="group" class="form-group" v-if="isEdit">
            <label class>{{$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE')}}</label>
            <div class="form-control-plaintext">
              <span>{{$t('POLICY_TYPE_AFFILIATE')}}</span>
            </div>
          </div>

          <ValidationProvider
            :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE')"
            v-if="!isEdit"
            v-slot="{ errors }"
            rules="required"
          >
            <CSelect
              :label="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE')"
              :value.sync="policy.type"
              :options="options"
            />
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{errors[0]}}</span>
            </p>
          </ValidationProvider>

          <ValidationProvider
            :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE')"
            v-slot="{ errors }"
            rules="required|min_value:0|max_value:100"
          >
            <div class="form-group">
              <label>{{$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE')}}</label>
              <currency-input
                v-model="policy.proportionShare"
                :precision="2"
                :currency="{ suffix: '%' }"
                class="form-control"
              />
            </div>
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{errors[0]}}</span>
            </p>
          </ValidationProvider>

          <h2 class="label">{{$t('ADD_AFFILIATE_POLICY_COMPONENT_RATE_TITLE')}}</h2>
          <div class="wrap-affiliate-form">
            <ul>
              <li v-for="(rate,index) in policy.rates" :key="index">
                <ValidationProvider
                  :name="`${$t('AFFILIATE_POLICIES_TEXT_LEVEL')} ${index + 1}`"
                  v-slot="{ errors }"
                  rules="required|min_value:0|max_value:100"
                >
                  <label>{{$t('AFFILIATE_POLICIES_TEXT_LEVEL')}} {{index + 1}}</label>
                  <div class="group-rates">
                    <currency-input
                      v-model="policy.rates[index]"
                      :precision="2"
                      :currency="{ suffix: '%' }"
                      class="form-control"
                    />
                    <CButton @click="policy.rates.splice(index, 1)">
                      <CIcon name="cil-minus" />
                    </CButton>
                  </div>
                  <p class="group-err-msg">
                    <span class="error-msg mt-1">{{errors[0]}}</span>
                  </p>
                </ValidationProvider>
              </li>
              <li class="wrap-btn">
                <CButton class="btn btn-add-rate" @click="policy.rates.push(0)">
                  <CIcon name="cil-plus" />
                </CButton>
              </li>
            </ul>
          </div>

          <CRow class="form-actions mt-3">
            <CCol lg="12" md="12">
              <CButton
                color="primary"
                class="btn btn-primary"
                @click="onSubmit"
              >{{$t('AFFILIATE_POLICIES_BUTTON_SAVE')}}</CButton>
            </CCol>
          </CRow>
        </CForm>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { MembershipTypeName } from '@/enums';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'AddAffiliatePolicy',
  data() {
    return {
      isEdit: false,
    };
  },
  props: {
    policy: {
      type: Object,
      default: () => {
        return {
          rates: [],
        };
      },
    },
    options: {
      type: Array,
      default: () => [],
    },
    currencyList: {
      type: Array,
      default: () => [],
    },
    policyId: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.policyId) {
      this.isEdit = true;
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const data = {
        name: this.policy.name,
        type: this.policy.type,
        currency_symbol: this.policy.currencySymbol || null,
        proportion_share: this.policy.proportionShare,
        description: this.policy.description,
        rates: this.policy.rates,
      };

      let msg = '';

      try {
        if (!this.isEdit) {
          msg = 'New policy has been created';
          await this.$http.post(endpoints.createAffiliatePolicy, data);
        } else {
          delete data.currency_symbol;
          msg = 'Policy has been updated';
          await this.$http.put(endpoints.updateAffiliatePolicy(this.policyId), data);
        }
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_SUCCESS_TITLE'),
          text: msg,
        });
        this.$router.back('affiliate-configuration/affiliate-policies');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('AFFILIATE_POLICIES_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
@mixin btn-handle-rate {
  width: 35px;
  height: 35px;
  font-size: 18px;
  line-height: 35px;
  font-weight: 700;
  color: #fff;
  background: #0d0da9;
  padding: 0;
  margin: 0 0 0 10px;
  border: none;
  box-shadow: none;
  vertical-align: top;
  svg {
    width: 18px;
    height: 18px;
    margin-top: 0px;
  }
}
.add-membership-policy-form {
  padding: 0 20px;
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #657187;
    margin-bottom: 20px;
  }
  .wrap-affiliate-form {
    ul {
      padding: 0;
      list-style-type: none;
      li {
        span {
          display: flex;
          flex: 0 0 100%;
          width: 100%;
          flex-wrap: wrap;
          label {
            font-size: 14px;
            font-weight: 300;
            line-height: 32px;
            text-align: right;
            color: #3a4a7f;
            padding-right: 10px;
            width: 35%;
          }
          .group-rates {
            width: 65%;
            input {
              font-size: 16px;
              font-weight: 500;
              color: #3a4a7f;
              display: unset;
              width: calc(100% - 45px);
            }
            button {
              @include btn-handle-rate;
            }
          }
          .group-err-msg {
            width: 65%;
            margin-left: 35%;
          }
        }

        &.wrap-btn {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin-bottom: 15px;
          .btn-add-rate {
            @include btn-handle-rate;
          }
        }
      }
    }
  }
}
</style>
