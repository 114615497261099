<template>
  <div class="add-membership-policy-form">
    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <CForm @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME')" v-slot="{ errors }" rules="required|max:100">
            <CInput :label="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_NAME')" v-model="policy.name" />
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </p>
          </ValidationProvider>

          <div role="group" class="form-group" v-if="isEdit && policy.currencySymbol">
            <label class>
              {{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY') }}
            </label>
            <div class="form-control-plaintext">
              <span>{{ policy.currencySymbol }}</span>
            </div>
          </div>

          <ValidationProvider name="ADD_AFFILIATE_POLICY_COMPONENT_CURRENCY_FIELD_NAME" v-slot="{ errors }" rules v-if="!isEdit">
            <div role="group" class="form-group">
              <label class>{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_CURRENCY') }}</label>
              <select v-model="policy.currencySymbol" class="form-control">
                <option value>{{ $t('ADD_AFFILIATE_POLICY_COMPONENT_PLEASE_SELECT_LABEL') }}</option>
                <option v-for="item in currencyList" :key="item.value" :value="item.value">{{ item.label }}</option>
              </select>
            </div>
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </p>
          </ValidationProvider>

          <div role="group" class="form-group" v-if="isEdit">
            <label class>
              {{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE') }}
            </label>
            <div class="form-control-plaintext">
              <span>{{ $t('POLICY_TYPE_MEMBERSHIP') }}</span>
            </div>
          </div>

          <ValidationProvider :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE')" v-if="!isEdit" v-slot="{ errors }" rules="required">
            <CSelect :label="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_TYPE')" :value.sync="policy.type" :options="options" />
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </p>
          </ValidationProvider>

          <ValidationProvider
            :name="$t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE')"
            v-slot="{ errors }"
            rules="required|min_value:0|max_value:100"
          >
            <div class="form-group">
              <label>
                {{ $t('ADD_AFFILIATE_POLICY_COMPONENT_LABEL_PROPORTION_SHARE') }}
              </label>
              <currency-input v-model="policy.proportionShare" :precision="2" :currency="{ suffix: '%' }" class="form-control" />
            </div>
            <p class="group-err-msg">
              <span class="error-msg mt-1">{{ errors[0] }}</span>
            </p>
          </ValidationProvider>

          <CDataTable class="mt-4" :items="paidMembershipTypes" :fields="fields" fixed hover striped bordered>
            <template ##="{item, index}">
              <td>{{ index + 1 }}</td>
            </template>

            <template #rate="{item}">
              <td class="field-membership-rate">
                <ValidationProvider
                  :name="`${$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_TABLE_COL_RATE_NAME')} ${item.name}`"
                  v-slot="{ errors }"
                  rules="required|min_value:0|max_value:100"
                >
                  <currency-input v-model="item.rate" :precision="2" :currency="{ suffix: '%' }" class="form-control" />
                  <p class="group-err-msg">
                    <span class="error-msg mt-1">{{ errors[0] }}</span>
                  </p>
                </ValidationProvider>
              </td>
            </template>
          </CDataTable>

          <CRow class="form-actions mt-3">
            <CCol lg="12" md="12">
              <CButton color="primary" class="btn btn-primary" @click="onSubmit">
                {{ $t('AFFILIATE_POLICIES_BUTTON_SAVE') }}
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { MembershipTypeName } from '@/enums';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'AddMembershipPolicy',
  data() {
    return {
      fields: [
        {
          key: '#',
          label: this.$t('ADD_MEMBERSHIP_POLICY_COMPONENT_LABEL_NUMBER'),
        },
        {
          label: this.$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_TABLE_COL_NAME'),
          key: 'name',
        },
        {
          label: this.$t('ADD_MEMBERSHIP_AFFILIATE_POLICY_COMPONENT_TABLE_COL_RATE'),
          key: 'rate',
          _style: 'width: 150px',
        },
      ],
      paidMembershipTypes: [],
      isEdit: false,
    };
  },
  props: {
    policy: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    currencyList: {
      type: Array,
      default: () => [],
    },
    policyId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['membershipTypes']),
  },
  mounted() {
    const paidMembershipTypes = this.membershipTypes
      .filter(e => e.is_enabled)
      .map(item => {
        return {
          ...item,
          rate: 0,
          displayOrder: item.display_order || 0,
        };
      });

    this.paidMembershipTypes = _.sortBy(paidMembershipTypes, 'displayOrder');

    if (this.policyId) {
      this.isEdit = true;
      this.parseMembershipRates();
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      const membershipRates = this.paidMembershipTypes.map(e => {
        return {
          membership_type_id: e.id,
          rate: e.rate,
        };
      });

      const data = {
        name: this.policy.name,
        type: this.policy.type,
        currency_symbol: this.policy.currencySymbol || null,
        proportion_share: this.policy.proportionShare,
        description: this.policy.description,
        membership_rates: membershipRates,
      };

      let msg = '';
      try {
        if (!this.isEdit) {
          msg = 'New policy has been created';
          await this.$http.post(endpoints.createAffiliatePolicy, data);
        } else {
          delete data.currency_symbol;
          msg = 'Policy has been updated';
          await this.$http.put(endpoints.updateAffiliatePolicy(this.policyId), data);
        }
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: msg,
        });
        this.$router.back('affiliate-configuration/affiliate-policies');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    parseMembershipRates() {
      const paidMembershipTypes = this.paidMembershipTypes;

      Object.entries(this.policy.membership_rate).forEach(arrays => {
        const membershipTypeId = arrays[0];
        const membershipType = paidMembershipTypes.find(item => item.id === membershipTypeId);
        if (membershipType) {
          membershipType.rate = arrays[1];
        }
      });

      this.paidMembershipTypes = paidMembershipTypes;
    },
  },
};
</script>

<style lang="scss">
.add-membership-policy-form {
  .form-control-plaintext {
    width: 65%;
  }

  .form-actions {
    text-align: right;
    .btn {
      border: none;
      border-radius: 4px;
      width: 145px;
      height: 35px;
      padding: 0 30px;
      color: #fff;
      margin: 0 0 15px 15px;

      &.btn-primary {
        background: #3b4b7e;
      }
    }
  }
}
</style>
