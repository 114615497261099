export const MembershipOrderStatus = {
  Pending: 'Verify payment',
  Rejected: 'Rejected',
  Approved: 'Approved',
};

export const getMembershipOrderStatuses = () => {
  return Object.keys(MembershipOrderStatus).map(key => {
    return {
      value: key,
      label: MembershipOrderStatus[key],
    };
  });
};
